.projects__grid {
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18em, 1fr));
  grid-gap: 2em;
}
.project{
	background-color: var(--clr-bg-alt);
	border: 2px solid var(--clr-fg-alt);
	border-radius: var(--border-radius);
	box-shadow: var(--btn-shadow);
	transform: translate(-7px, -7px);
	transition: var(--transition);
}
.project:hover{
	box-shadow: none;
	transform: translate(0, 0);
	cursor: pointer;
}