.project {
	padding: 2rem 1.875rem;
	margin: 0.5em auto;
	text-align: center;
	box-shadow: var(--shadow);
	transition: transform 0.2s linear;
}

.project:hover {
	transform: translateY(-7px);
}

.project__description {
	font-family: var(--poppins);
	color: var(--clr-fg-alt);
	margin-top: 1em;
	font-size: 0.90em;
	line-height: 24px;
}

.project__stack {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 1em 0 0;
}

.project__stack-item {
	margin: 0.5em 0;
	font-weight: 500;
	font-size: 0.8rem;
	color: var(--clr-fg-alt);
	border-right: 2px solid var(--blue);
	padding: 0 1em;
}
.project__stack-item:last-child{
	border-right: none;
}

.project .link--icon {
	margin-left: 0.5em;
}
