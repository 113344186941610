.header {
	height: 8em;
	max-width: var(--max-width);
	width: 95%;
	margin: 0 auto;
	justify-content: space-between;
}

@media (max-width: 600px) {
	.header {
		height: 6em;
	}
}
