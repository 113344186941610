.about {
	flex-direction: column;
	margin-top: 3em;
}

.about__name {
	color: var(--blue);
}

.about__role {
	margin-top: 1.2em;
}

.about__desc {
	font-size: 1rem;
	max-width: 600px;
}

.about__desc,about__name
.about__contact {
	margin: 3em 0;
}

.about .link--icon {
	margin-right: 0.8em;
}

.about .btn--outline {
	margin-right: 1em;
}
